<template>
    <b-alert
        :show="message.length > 1"
        :dismissible="dismissable"
        :variant="variant"
    >
        <font-awesome-icon :icon="icon" />
        {{ message }}
    </b-alert>
</template>
<script>
export default {
    props: {
        message: { type: String, default: "" },
        success: { type: Boolean, default: false },
        dismissable: { type: Boolean, default: false }
    },
    computed: {
        icon() {
            return (this.success) ? 'info-circle' : 'times-circle';
        },
        variant() {
            return (this.success) ? 'info' : 'danger';
        }
    }
}
</script>