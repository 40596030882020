<template>
    <div>
        <ResponseAlert
            :success="success"
            :message="message"
        />
        <b-card>
            <h2>Invite Member</h2>
            <b-form-group
                label="Invitee's email"
            >
                <b-input-group>
                    <b-input
                        v-model="invitee_email"
                        placeholder="Email address"
                        :disabled="working"
                    ></b-input>
                    <b-button
                        @click="sendInvite()"
                        variant="primary"
                    >
                        <b-spinner v-if="working" small class="mr-2" />
                        Send Invite
                    </b-button>
                </b-input-group>
            </b-form-group>
        </b-card>
    </div>
</template>
<script lang="js">
import ResponseAlert from "@/components/alerts/ResponseAlert.vue";
export default {
    props: ["group_id"],
    components: { ResponseAlert },
    data() {
        return {
            invitee_email: "",
            working: false,
            message: "",
            success: false
        };
    },
    methods: {
        async sendInvite() {
            this.working = true;
            const { data: { message, success } } = await this.$api.post(
                "/api/group/request/admin/invite",
                { email: this.invitee_email }
            );
            this.invitee_email = "";
            this.success = success;
            this.message = message;
            this.working = false;
        }
    }
}
</script>